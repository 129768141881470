.comestri-subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.comestri-subtext {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #585858;
}

.comestri-subtext-info {
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
  color: #585858;
}

/* ####################################### */
/* MUI styling override */
/* ####################################### */

/* TextField => input */
.MuiOutlinedInput-input {
  padding: 0px 8px !important;
  height: 32px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 32px !important;
  color: #585858 !important;
}

.MuiSelect-outlined {
  max-height: 32px !important;
  padding: 0px 8px !important;
}

/* TextField => DataTimerPicker */
.MuiInputAdornment-root {
  width: 12px !important;
}

.data-timer-picker {
  /* padding: 0px 8px !important; */
  height: 32px !important;
}

.data-timer-picker > div > div > input {
  padding: 0px !important;
  height: 32px !important;
}

/* TextField => Chips Box */
.MuiInputBase-sizeSmall {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 32px !important;
  /* flex-wrap: nowrap !important; */
}

/* Button => with small and contained */
.MuiButton-containedSizeSmall {
  border-radius: 32px !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  text-transform: none !important;
  padding: 6px 16px !important;
  height: 32px !important;
}

.MuiButton-textSizeSmall {
  border-radius: 32px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 32px !important;
  text-transform: none !important;
  padding: 6px 16px !important;
  height: 32px !important;
}

/* Chips => with small */
.MuiChip-sizeSmall {
  background: #040001 !important;
  border-radius: 4px !important;
  margin: 4px 2px !important;
}
/* Chips - label => with small */
.MuiChip-labelSmall {
  color: #ffffff;
  /* max-width: calc(100% - 24px) !important; */
}

/* Chips in Autocomplete */
.MuiAutocomplete-tag {
  max-width: calc(100% - 112px) !important;
}

.MuiAutocomplete-endAdornment {
  display: none;
}
